@import 'src/scss-variables/mixins/aspect-ratio.scss';

.text {
	text-decoration: none;
	color: var(--color-text-default);
}

.courseCard {
	border: 0;
	padding: 0;
	text-align: left;
	border-radius: 4px;
	background-color: var(--color-background-light);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	@extend .text;
	&:hover {
		@extend .text;
	}
}

.cardImage {
	@include aspect-ratio(30, 13);
	position: relative;
	background-color: var(--color-background-panel);
	background-position: center;
	background-size: cover;
}

.draftTag,
.favoriteTag {
	position: absolute;
	background-color: var(--color-background-light);
	padding-left: var(--spacing-1x);
	padding-right: var(--spacing-1x);
	font-family: 'MuliSemiBold';
	border-radius: 4px;
	top: var(--spacing-2x);
}

.draftTag {
	left: var(--spacing-2x);
}

.favoriteTag {
	right: var(--spacing-2x);
}

.heartIcon {
	width: 12px;
	height: 12px;
	margin-bottom: 2px;

	path {
		fill: var(--color-text-default);
	}

	&.favorite path {
		fill: var(--color-primary);
	}
}

.cardDetails {
	padding: var(--spacing-1x) var(--spacing-4x) var(--spacing-2x) var(--spacing-4x);
}

.metaDetails {
	display: inline-block;
	color: var(--color-text-meta);
	height: var(--spacing-4x);
	font-size: 10px;
}

.gradeAndSubject {
	color: var(--color-border-light);
	text-transform: uppercase;
	font-weight: bold;
	font-family: 'MuliBold';
	font-size: 9px;
}

.cardAuthor {
	font-size: 9px;
	font-family: 'MuliBold';
	color: var(--color-text-meta);
}

.authors {
	svg {
		height: var(--spacing-4x);
		width: var(--spacing-4x);
	}
	padding-bottom: var(--spacing-2x);
}

.authorsText {
	padding-left: var(--spacing-1x);
	font-size: 12px;
}

.cardTitle {
	font-family: 'MuliBold';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.middot {
	color: var(--color-primary);
	padding-left: var(--spacing-1x);
	padding-right: var(--spacing-1x);
}

.avatar {
	height: 16px;
	width: 16px;
	border-radius: 50%;
}