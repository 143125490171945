.thumbnail {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	cursor: pointer;
}

.thumbnailTitle {
	max-width: 320px;
}
