.exerciseView {

}

.exerciseHeader {
	background-color: var(--color-background-panel); 
	padding: 14px var(--spacing-4x) var(--spacing-4x) var(--spacing-4x);
	svg {
		margin-right: var(--spacing-1x);
	}
	path {
		fill: var(--color-primary);
	}
}

.title {
	margin-bottom: 0;
	margin-left: 1px;
}

.exerciseText {
	padding-top: var(--spacing-2x);
	padding-left: var(--spacing-4x);
}