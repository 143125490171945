.search {
	padding: var(--spacing-8x) var(--spacing-4x);
	display: grid;
	grid-template-columns: auto 90px;
	background-color: var(--color-background-panel);
}

.searchButton {
	background-color: #DDDDDD;
	border: 1px solid #DDDDDD;
	svg {
		height: var(--spacing-6x);
	}
}

.searchTextField {
	input {
		outline: none;
		border-radius: 0;
		border: 1px solid #DDDDDD;
		border-right: 0;
	}
}