h1 {
	font-size: 60px;
	line-height: 80px;
	font-family: MuliBold;
}

h2 {
	font-size: 24px;
	line-height: 36px;
	font-family: MuliBold;
}

h3 {
	font-size: 20px;
	line-height: 36px;
	font-family: MuliBold;
}

h4 {
	font-size: 16px;
	line-height: 24px;
	font-family: MuliBold;
}

h5 {
	font-size: 16px;
	line-height: 24px;
	font-family: MuliRegular;
}