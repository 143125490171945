.button {
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	user-select: none;
	font-family: 'MuliSemiBold';
	width: 200px;
	&:focus {
		outline: none;
	}
}

button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.block {
	display: block;
	width: 100%;
}

.meta {
	background-color: transparent;
	border: none;
	color: var(--color-text-meta);
	fill: var(--color-text-meta);
}

.primary {
	background-color: var(--color-primary);
	color: var(--color-text-light);
	fill: var(--color-text-light);
	border: 1px solid var(--color-primary);
	&:hover {
		background-color: var(--color-primary-hover);
	}
}

.secondary {
	background-color: var(--color-text-light);
	color: var(--color-primary);
	fill: var(--color-primary);
	border: 1px solid var(--color-primary);
}

.ghostPrimary {
	color: var(--color-primary);
	fill: var(--color-primary);
	border: 1px solid var(--color-primary);
	background-color: transparent;
}

.ghostSecondary {
	color: var(--color-text-light);
	fill: var(--color-text-light);
	border: 1px solid var(--color-background-light);
	background-color: transparent;
}

.ghostRounded {
	color: var(--color-text-default);
	fill: var(--color-text-default);
	border: 1px solid var(--color-border-dark);
	width: auto;
	border-radius: 20px;
	background-color: transparent;
}

.roundedPrimary {
	color: var(--color-text-light);
	fill: var(--color-text-light);
	border: 1px solid var(--color-primary);
	width: auto;
	border-radius: 20px;
	background-color: var(--color-primary);
	&:hover {
		background-color: var(--color-primary-hover);
	}
}

.success {
	// define me
}

.danger {
	color: var(--color-primary);
	fill: var(--color-primary);
	border: 1px solid var(--color-primary);
	background-color: transparent;
}

.warning {
	// define me
}

.info {
	// define me
}

.text {
	background-color: var(--color-background-light);
	color: var(--color-text-default);
	fill: var(--color-text-default);
	border: 1px solid var(--color-background-light);
}

.inline {
	font-family: inherit;
	width: auto;
	border: none;
	background-color: transparent;
	padding: 0 var(--spacing-2x);
	line-height: inherit;

	&:hover,
	&:active {
		text-decoration: underline;
	}

	&:focus {
		outline: none;
		background-color: var(--color-background-panel);
	}
}
