.searchAuthors {
	padding: var(--spacing-1x) var(--spacing-4x) var(--spacing-8x)
		var(--spacing-4x);
	display: grid;
	grid-template-columns: auto 90px;
	background-color: var(--color-background-panel);
}

.searchResult {
	padding: var(--spacing-4x);
}

.searchButton {
	background-color: #dddddd;
	border: 1px solid #dddddd;
	border-radius: 0;
	svg {
		height: var(--spacing-6x);
	}
}

.searchAuthorsField {
	input {
		outline: none;
		border-radius: 0;
		border: 1px solid #dddddd;
		border-right: 0;
	}
}

.author {
	display: inline-block;
}

.addAuthorButton {
	display: inline-block;
	margin-bottom: var(--spacing-1x);
}

.loadingAuthors {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: var(--spacing-2x);
	width: 249px;
	padding-left: var(--spacing-4x);
	padding-bottom: 26px;
	padding-top: var(--spacing-2x);
}
