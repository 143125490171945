.course {

}

.sbjectsGradesCategories {
	padding-left: var(--spacing-4x);
	padding-top: var(--spacing-6x);
	button {
		margin-bottom: var(--spacing-2x);
	}
}
