@mixin selected-circle-inRange {
    .side {
        position: relative;

        &::before {
            content: '';
            z-index: -1;
            width: 100%;
            height: 50%;
            position: absolute;
            background-color: var(--color-background-panel);
            @content;
        }
    }
}

.element {
    display: flex;
    height: var(--spacing-8x);
    margin: 0 var(--spacing-4x);
}

.side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-6x);
    margin-right: var(--spacing-2x);
    cursor: pointer;
}

.name {
    line-height: var(--spacing-8x);
    cursor: pointer;
}

.circle {
    width: 12px;
    height: 12px;
    border: 1px solid var(--color-border-light);
    border-radius: 50%;
    transition: all 0.2s ease;
}

.selected {
    .circle {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        width: var(--spacing-6x);
        height: var(--spacing-6x);
    }

    &.inRange {
        .inRange + & {
            @include selected-circle-inRange {
                top: 0;
            }
        }

        :not(.inRange) + &,
        &:first-child {
            @include selected-circle-inRange {
                bottom: 0;
            }
        }
    }
}

.inRange:not(.selected) {
    .side {
        background-color: var(--color-background-panel);
    }

    .circle {
        background-color: var(--color-border-light);
    }
}
