.imageView {
	min-height: 423px;
}

.image {
	width: 100%;
	height: 100%;
}

.noImage {
	height: 423px;
	background: var(--color-background-panel);
	text-align: center;
	padding-top: 168px;
	cursor: pointer;
}

.noImageIcon {
	height: 28px;
	width: 28px;
}

.noImageText {
	margin-top: 8px;
	font-family: MuliBold;
	color: var(--color-text-meta);
	font-size: 16px;
}

.editable {
	cursor: pointer;
	&:hover {
		opacity: 0.8;
		background-color: var(--color-background-panel);
	}
}
