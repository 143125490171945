.element {
	background-color: var(--color-background-light);
	border: 2px solid transparent;
	margin-bottom: var(--spacing-6x);
	overflow-x: hidden;
}

.isDragging {
	// background-color: var(--color-background-panel);
	border: 2px dashed var(--color-border-light);
}

.dragHandleWrapper {
	position: relative;
	height: 0;
}

.dragHandle {
	svg {
		height: 32px;
		width: 32px;
	}
	g {
		fill: var(--color-text-meta);
	}
	position: absolute;
	right: -34px;
}
