.header {
	background-color: var(--color-background-panel);
	padding: var(--spacing-8x);
}

.learningObjectiveTextField {
	textarea {
		font-family: 'MuliSemiBold';
		border: 0;
		border-radius: 0;
		background-color: var(--color-background-panel);
		outline: none;
		border-bottom: 1px solid var(--color-border-light);
		padding: 0;
	}
}

.footer {
	padding-top: var(--spacing-8x);
	text-align: center;
}

.deleteButton {
	display: block;
	margin: var(--spacing-8x) auto var(--spacing-4x);
}
