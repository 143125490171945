.teacherInstructions {
	position: relative;
}

.title {
	font-family: MuliBold;
	font-size: 16px;
	position: absolute;
	left: 16px;
	top: 18px;
}

.editable {
	&:hover {
		background-color: var(--color-background-panel);
		cursor: pointer;
	}
}