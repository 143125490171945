.switchButton {
	--button-height: 24px;
	--button-width: 40px;
	--slider-size: 18px;
	--button-padding: calc((var(--button-height) - var(--slider-size)) / 2);
	/* Edge can't handle calc() in css-variables in translate...
	Should be: calc(var(--button-width) - var(--slider-size) - (2 * var(--button-padding))) */
	--slider-translate-x: 16px;

	.checkbox {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
	}

	.label {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);
		width: var(--button-width);
		background-color: var(--color-text-meta);
		border-radius: calc(0.5 * var(--button-height));
		cursor: pointer;

		&.isSelected {
			background-color: var(--color-primary);
			&:hover {
				background-color: var(--color-primary);
			}

			&:after {
				transform: translateX(var(--slider-translate-x));
				background: white;
			}

			.toggleCircle {
				transform: translateX(16px);
			}
		}

		&.isDisabled {
			background: var(--color-border-light);
			pointer-events: none;

			&:after {
				background: var(--color-background-panel);
			}
		}

		&.hasFocus {
			box-shadow: 0 0 0 4px var(--color-border-light), inset 0 0 0 1px var(--color-border-light);
		}

		.toggleCircle {
			transform: translate(0px);
			position: relative;
			margin: 0 var(--button-padding);
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--slider-size);
			height: var(--slider-size);
			border-radius: 50%;
			background: #fff;
			transition: transform 0.2s ease;

			&:active {
				transform: scale(1.11, 0.9);
			}
		}
	}
}
