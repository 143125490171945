.createPage {
	
}

.beginAddPage {
	text-align: center;
	color: var(--color-text-meta);
	margin-bottom: 0;
	height: 47px;
	line-height: 47px;
	&:hover {
		background-color: var(--color-background-panel);
		cursor: pointer;
	}
}

.createNewPageText {
	input {
		background-color: var(--color-background-panel);
		outline: none;
		border: 0;
		border-radius: 0;
		font-size: 24px;
		font-family: MuliBold;
		height: 47px;
	}
}