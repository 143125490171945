.settingContainer {
	display: grid;
	grid-template-areas: "text icon button";
	grid-template-columns: max-content 1fr 1fr;
	padding-left: var(--spacing-4x);
	align-items: center;
	grid-gap: var(--spacing-2x);

	h4 {
		grid-area: text;
		margin: 0;
	}

	svg {
		grid-area: icon;
	}
}

.switch {
	justify-self: end;
	grid-area: button;
}

.deleteCourseButton {
	display: block;
	margin: var(--spacing-8x) auto var(--spacing-4x);
}
