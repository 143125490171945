.firstSection {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.secondSection {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.boardGameArt {
	min-width: 280px;
	height: 360px;
}

.imageWrapper {
	text-align: center;
	display: flex;
	justify-content: center;
}

.innovationsfondenArtWrapper {
	padding-top: var(--spacing-1x);
	display: flex;
}

.innovationsfondenArt {
	height: 50px;
	min-width: 280px;
}