.learningObjectiveView {
	
}

.editable {
	cursor: pointer;
}


.learningObjectiveText {
	display: grid;
	grid-template-columns: 0.4fr 14fr;
	column-gap: var(--spacing-4x);
	background: var(--color-background-panel);
	padding: var(--spacing-4x);
	font-family: 'MuliSemiBold';
}

.subObjectivesList {
	@extend .learningObjectiveText;
	background: 0;
	font-family: 'MuliLight';
}

.bullet {
	width: 32px;
	padding-left: 12px;
}
