.fileUpload {
    padding: var(--spacing-4x);
    background-color: var(--color-background-panel);
}

.dropArea {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.dropAreaIcon {
    text-align: center;
    path {
        fill: var(--color-text-meta);
    }
    svg {
        height: 28px;
        width: 28px;
        margin-bottom: var(--spacing-2x);
    }
}

.dropAreaText {
    font-family: MuliBold;
    color: var(--color-text-meta);
    font-size: 16px;
}

.titleField{
    input {
        border-radius: 0;
    }
}