.siteFooter {
	background-color: #2b2d2e;
	color: #9b9b9b;
	padding-bottom: 113px;
}

.leftCol {
	@media (min-width: 768px) {
		padding-top: 113px;
	}
	@media (max-width: 575px) {
		padding-top: 45px;
		padding-bottom: 70px;
		padding-left: 47px;
	}
}

.rightCol {
	text-align: right;
	@media (min-width: 768px) {
		padding-top: 113px;
	}
	@media (max-width: 575px) {
		text-align: center;
	}
}

.list {
	list-style: none;
	padding: 0;
}

.aauLogo {
	height: 132px;
}

.versionNumber {
	font-size: 10px;
}