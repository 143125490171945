.cards {
	margin: 0 auto;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	padding-top: var(--spacing-4x);
}

.searchField {
	width: 100%;
	input {
		width: 100%;
	}
}

.filtering {
	display: flex;
	align-items: left;
	margin-top: var(--spacing-4x);

	.buttons {
		flex-grow: 1;
		padding-top: var(--spacing-2x);

		button {
			margin-bottom: var(--spacing-2x);
		}

		> button {
			font-size: 12px;
		}

		> * {
			margin-right: var(--spacing-2x);
		}
	}
}

.searchForm {
	position: relative;
	input {
		padding-left: 41px;
	}
	svg {
		position: absolute;
    z-index: 2;
    height: 20px;
    top: 14px;
    left: 5px;
	}
	path {
		fill: var(--color-text-meta);
	}
}

@media (max-width: 768px) {
	.filtering {
		flex-direction: column;
		align-items: left;
	}
}

@media (min-width: 768px) {
	.searchField {
		width: 400px;
	}	
}

button.showMoreButton {
	display: block;
	margin: var(--spacing-4x) auto 0;
}
