@import './thumbnail.module.scss';

.thumbnail {
	@extend .thumbnail;
}

.thumbnailWithImage {
	@extend .thumbnailWithImage;
	gap: var(--spacing-4x);
}

.media {
	@extend .media;
}

.thumbnailContent {
	@extend .thumbnailContent;
	padding: 0;
}