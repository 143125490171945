.teacherInstructionsView {
	padding-top: 48px;
	padding-left: var(--spacing-4x);
	padding-bottom: var(--spacing-2x);
}

.text {
	overflow: hidden;
	padding-top: var(--spacing-1x);
	padding-bottom: var(--spacing-1x);
	padding-left: var(--spacing-2x);
	margin-top: calc(var(--spacing-1x) * -1);
	margin-left: calc(var(--spacing-2x) * -1);
	border-radius: var(--spacing-1x);
}

.editable {
	cursor: pointer;
	&:hover {
		background-color: var(--color-background-panel);
	}
}

.noText {
	@extend .text;
	color: var(--color-text-meta);
}
