$size: 28px;

.wrapper {
	display: inline-flex;
	align-items: top;
	position: relative;
	user-select: none;
	cursor: pointer;

	&.readonly,
	&.disabled {
		cursor: default;
	}

	&.block {
		display: flex;
	}

	> input {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
	}

	&.disabled {
		color: var(--color-text-meta);
		padding: 0;
	}
}

.label {
	display: inline-block;
	width: calc(100% - #{$size});
	position: relative;
	top: 1px;

	&:not(:empty) {
		padding-left: var(--spacing-2x);
	}
}

.checkmarkContainer {
	position: relative;
	width: $size;
	height: $size;
	display: inline-block;
	border-radius: 50%;
	border: 0;
	transition: box-shadow 0.2s ease;

	input:focus + & {
		box-shadow: 0 0 0 4px var(--color-border-light), inset 0 0 0 1px var(--color-border-light);
	}
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity 0.2s ease;
	width: $size;
	height: $size;
	border-radius: 50%;
}

input:checked ~ .checkmarkContainer .checkedMark,
input:not(:checked) ~ .checkmarkContainer .uncheckedMark {
	opacity: 1;
}

.checkedMark path {
	fill: var(--color-primary);
}

.uncheckedMark path {
	fill: var(--color-text-meta);
}

.checkmark.disabled {
	background-color: var(--color-background-panel);

	path {
		fill: var(--color-border-light);
	}
}
