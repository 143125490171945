@import './colors.scss';
@import './spacing.scss';
@import './headings.scss';
@import './breakpoints.scss';

.colors {
	--color-primary: #{$--color-primary};
	--color-primary-hover: #{$--color-primary-hover};
	--color-background-light: #{$--color-background-light};
	--color-text-light: #{$--color-text-light};
	--color-background-panel: #{$--color-background-panel};
	--color-text-default: #{$--color-text-default};
	--color-text-meta: #{$--color-text-meta};
	--color-border-light: #{$--color-border-light};
	--color-border-dark: #{$--color-border-dark};
}

.spacing {
	--spacing-1x: #{$--spacing-1x};
	--spacing-2x: #{$--spacing-2x};
	--spacing-4x: #{$--spacing-4x};
	--spacing-6x: #{$--spacing-6x};
	--spacing-8x: #{$--spacing-8x};
}

.breakpoints {
	--min-size-desktop: $--min-size-desktop;

	--min-size-tablet: $--min-size-tablet;
	--max-size-tablet: $--max-size-tablet;

	--max-size-mobile: $--max-size-mobile;
}

:root {
	@extend .colors;
	@extend .spacing;
}


html {
	font-size: 16px;
	font-family: MuliRegular;
}

label {
	font-size: 16px;
}

textarea {
	min-height: 75px;
}

input {
	outline: none;
}

// TINY MCE

.tox.tox-tinymce {
	border: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}