.title {
	margin-bottom: var(--spacing-8x);
}

.email {
	margin-bottom: var(--spacing-4x);
}

.password {
	margin-bottom: var(--spacing-4x);
}

.links {
	margin-top: var(--spacing-6x);
	color: var(--color-text-meta);
	display: flex;
	align-items: center;
	flex-direction: column;
}

.validationError {
	color: var(--color-primary);
	margin-bottom: var(--spacing-4x);
}