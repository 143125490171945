.resetPassword {
    text-align: center;
}

.textField {
    margin-bottom: var(--spacing-4x);
}

.buttons {
    text-align: center;
}