.imageView {
	display: block;
	width: 100%;
	min-height: 100px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	transition: all 0.2s ease;
}

.descriptionText {
	padding-top: var(--spacing-4x);
	padding-left: var(--spacing-4x);
}

.editable {
	cursor: pointer;
	&:hover {
		opacity: 0.8;
		background-color: var(--color-background-panel);
	}
}
