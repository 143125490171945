.createElement {
	border: 1px solid var(--color-border-dark);
	text-align: center;
	margin: var(--spacing-4x) 0;
}

.editElement {
	margin: var(--spacing-4x) 0;
}

.elementIcon {
	border: none;
	background: none;
	display: inline-block;
	padding: var(--spacing-2x) var(--spacing-2x);
	&:hover {
		cursor: pointer;
		path {
			fill: var(--color-primary);
		}
	}
}