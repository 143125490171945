.authorsEdit {

}

.selectedAuthors {
	padding: var(--spacing-4x);
	background-color: var(--color-background-panel);
}

.author {
	display: inline-block;
}

.removeAuthorButton {
	margin-bottom: var(--spacing-1x);
}