@import 'scss-variables/breakpoints.scss';

.coursePage {
	display: grid;
	grid-template-columns: 3fr 7fr;

	@media (max-width: $--max-size-mobile) {
		grid-template-columns: 50% 50% 50%;
		grid-template-rows: var(--spacing-8x) 1fr;
		overflow: hidden;
	}
}

.saveMenu {
	@extend .coursePage;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	align-items: center;

	.navMenuIndent {
		padding-top: 10px;
		padding-right: var(--spacing-8x);
	}

	@media (max-width: $--max-size-mobile) {
		grid-template-columns: 1fr;
		grid-template-rows: 48px 12px;

		.navMenuIndent {
			padding-right: 0;
		}
	}
}

// Only show when on mobile
.toggleMenu {
	display: none;

	@media (max-width: $--max-size-mobile) {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 12px;
		background: #f9f9f9;
		padding-left: 12px;
		align-items: center;
		grid-column-start: 1;
		grid-column-end: span 3;
		max-width: var(--max-width);
		fill: #4a4a4a;
		color: #4a4a4a;
		font-weight: bold;
	}
}

.noRotation {
	transform: rotate(0deg);
	transition: 0.4s;
}

.rotate {
	transition: 0.4s;
	transform: rotate(180deg);
}

.pageContent {
	grid-column-start: 2;
	grid-column-end: span 3;

	@media (max-width: $--max-size-mobile) {
		grid-column-start: 1;
		grid-column-end: span 2;

		.showNavigationItems & {
			grid-column-start: 2;
			grid-column-end: span 3;
		}
	}
}

// Only show when NOT on mobile
.navigationMenu {
	grid-column-start: 1;
	grid-column-end: span 1;

	@media (max-width: $--max-size-mobile) {
		display: none;

		.showNavigationItems & {
			display: block;
		}
	}
}

.createNewChapterButton {
	width: 100%;
	color: var(--color-text-meta);
	display: block;
	width: 100%;
	text-align: left;
	border: none;
	border-radius: 0;
	padding-left: 6px;

	padding-top: var(--spacing-4x);
	&:hover {
		background-color: var(--color-background-panel);
	}
}
