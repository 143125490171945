.header {

}

.creatingText {
	text-align: left;
	width: 100px;
}

.createCourseButton {
	float: right;
}