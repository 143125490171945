.textView {
	padding: var(--spacing-4x);
	background: var(--color-background-panel);
}

.tagLine {
	display: block;
	font-weight: 600;
	margin-bottom: var(--spacing-2x);
}
