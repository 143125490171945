.editorTitle {
	padding-left: var(--spacing-4x);
	line-height: 38px;
}

.icon {
	padding-right: var(--spacing-2x);
	svg {
		height: var(--spacing-8x);
		width: var(--spacing-8x);
	}
	path {
		fill: var(--color-primary);
	}
}

.title {
	display: inline-block;
}