.durationView {
	
}

.text {
	overflow: hidden;	
	padding-top: var(--spacing-1x);
	padding-bottom: var(--spacing-1x);
	padding-left: var(--spacing-2x);
	margin-top: calc(var(--spacing-1x) * -1);
	margin-left: calc(var(--spacing-2x) * -1);
	border-radius: var(--spacing-1x);
}

.noText {
	padding-bottom: var(--spacing-1x);
	color: var(--color-text-meta);
}

.editable {
	&:hover {
		cursor: pointer;
		background-color: var(--color-background-panel);
	}
} 