.blogPosts {
	margin-bottom: var(--spacing-8x);

	.linkContainer {
		color: var(--color-text-meta);
		font-family: MuliBold;

		.link {
			color: inherit;
		}
	}
}

.thumbnailGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
}

@media(max-width: 991px) {
	.thumbnailGrid {
		display: block;
	}
}