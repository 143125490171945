.page {
	
}

.pageContent {
	padding-top: var(--spacing-4x);
}

.elements {
	background-color: var(--color-background-light);
	transition: background-color 0.2s ease;
}

.isDraggingOver {
	// background-color: var(--color-background-panel);
}