.durationEdit {
	input {
		border: 0;
		outline: none;
		background: var(--color-background-panel);
		
		padding-top: var(--spacing-1x);
		padding-bottom: var(--spacing-2x);
		padding-left: var(--spacing-2x);
		margin-top: calc(var(--spacing-1x) * -1);
		margin-left: calc(var(--spacing-2x) * -1);
		margin-right: -32px;
		border-radius: var(--spacing-1x);
		width: calc(100% + var(--spacing-2x));
		height: auto;
		
		&:focus {
			background: var(--color-background-panel);
		}
	}
}
