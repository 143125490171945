.author {
	padding-bottom: var(--spacing-2x);
}

.authorName {
	font-family: MuliBold;
	font-size: 16px;
	line-height: 12px;
}

.avatar {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-right: var(--spacing-2x);
}