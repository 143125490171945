.youtubeView {

}

.videoDescription {
	padding: var(--spacing-4x) 0 var(--spacing-4x) var(--spacing-4x);
}

.editable {
	cursor: pointer;
	&:hover {
		background-color: var(--color-background-panel);
	}
}