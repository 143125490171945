.learningObjectiveTextField {
	input {
		border: 0;
		border-radius: 0;
		&:hover, &:focus {
			background-color: var(--color-background-panel);
		}
	}
}

.subObjectives {
	padding: var(--spacing-8x);
}

.subObjective {
	display: grid;
	grid-template-columns: 0.4fr 8fr 1fr;
}

.deleteSubObjectiveBtn {
	background: 0;
	border: 0;
	width: auto;
	margin-top: var(--spacing-2x);
	svg {
		color: var(--color-primary);
	}
}

.bullet {
	width: 32px;
	padding-top: 12px;
}

