@import '../../../../components/text-field/text-field.module.scss';

.titleEdit {
	@extend .textField;
	border: none;
	line-height: 48px;
	border-radius: 0;
	font-size: 24px;
	font-family: MuliBold;
	padding-left: 0;
	padding-right: 0;
	input {
		background-color: var(--color-background-panel) !important;
		outline: none;
		border: 0;
		border-radius: 0;
	}
}