.gradesEdit {
	width: 375px;
	position: absolute;
	z-index: 3;
	background: var(--color-background-light);
	top: 48px;
}

.gradesEditContent {
	display: grid;
	height: 516px;
	padding: var(--spacing-4x) var(--spacing-4x) var(--spacing-4x) var(--spacing-4x);
	overflow-y: scroll;
}