.subjectsView {
	button {
		font-size: 12px;
	}
}

.notEditable {
	outline: 0;
	&:focus {
		outline: 0;
	}
	&:hover {
		cursor: default;
		background-color: var(--color-primary) !important;
	}
}