.createLearningObjective {
	padding: var(--spacing-6x) 0 var(--spacing-4x) 0;
	padding: var(--spacing-2x) 0;
}

.createLearningObjectiveTitle {
	color: var(--color-text-meta);
	padding: var(--spacing-4x) 0 var(--spacing-4x) var(--spacing-4x);
	margin-bottom: 0;
	svg {
		margin-right: var(--spacing-4x);
	}
	&:hover {
		cursor: pointer;
		background-color: var(--color-background-panel);
	}
}
