$previewSize: 120px;

.element {
	padding-bottom: var(--spacing-4x);
}

.editable .element {
	cursor: pointer;

	&:hover {
		background-color: var(--color-background-panel);
	}
}

.title {
	padding: var(--spacing-2x) var(--spacing-4x);
	font-family: MuliSemiBold;
}

.shortDescription {
	padding: 0 var(--spacing-4x);
	height: auto;
}

.readMore {
	margin: 0 var(--spacing-2x);

	&,
	&:hover {
		color: var(--color-text-meta);
	}
}

.preview {
	display: grid;
	width: 100%;
	grid-template-columns: $previewSize 1fr;
	grid-template-rows: var(--spacing-8x) 1fr;
	height: $previewSize;
	background-color: var(--color-background-panel);

	&,
	* {
		&,
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}

	&:hover .url {
		color: var(--color-text-default);
	}
}

.image {
	grid-row-start: 1;
	grid-row-end: span 2;
	width: $previewSize;
	height: $previewSize;
	background-color: var(--color-border-light);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	color: transparent;
	&:hover {
		text-decoration: none;
		color: transparent;
	}
}

.url {
	padding: var(--spacing-2x) var(--spacing-4x) 0;
	color: var(--color-text-meta);

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.description {
	padding: 0 var(--spacing-4x);
	font-family: MuliSemiBold;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 120px;
}

.iconPreviewContainer {
	width: $previewSize;
	height: $previewSize;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-border-light);
}
