.title {
	color: var(--color-text-light);
	margin-bottom: var(--spacing-8x);
}

.name {
	margin-bottom: var(--spacing-4x);
}

.email {
	margin-bottom: var(--spacing-4x);
}

.password {
	margin-bottom: var(--spacing-4x);
}

.name,
.email,
.password {
	:global(.errorMessage) {
		color: white;
	}
}


.termsOfUseText {
	padding-top: var(--spacing-4x);
	text-align: left;
	color: var(--color-text-dark);
	font-size: 14px;
}