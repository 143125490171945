.wrapper {
	position: relative;
}

.textField {
	height: 47px;
	border: 1px solid var(--color-border-light);
	border-radius: 4px;
	padding-right: 8px;
	width: 100%;
	padding-top: 0;
	transition: all 0.2s ease;
	padding-left: var(--spacing-4x);
	&.hasLabelText:focus {
		padding-top: var(--spacing-2x);
	}
}

.labelText {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 18px;
	font-size: 10px;
	color: var(--color-text-meta);
	transition: all 0.2s ease;

	input:focus + & {
		opacity: 1;
		top: var(--spacing-1x);
	}
}
