.editor {
	border: 1px solid var(--color-text-meta);
	border-radius: 4px;
	animation-name: animateIn;
	animation-duration: 0.2s;
}

.header {
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid var(--color-text-meta);
	.name {
		padding: 16px 0px 8px 16px;
		font-family: MuliBold;
		font-size: 16px;
	}
}

.headerActionsLeft {
	padding: 8px 16px 8px 0;
}

.headerActionsRight {
	padding: 8px 16px 8px 0;
}

.headerActions {
	text-align: right;
}

.headerActionsGroup {
	float: right;
}

.footer {
	text-align: center;
	padding-bottom: 32px;
}

.popoverMobileOffset {
	position: absolute;
	background-color: white;
	z-index: 2;
}

.mobileWidth {
	width: auto
}

@keyframes animateIn {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}
