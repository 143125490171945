.authors {
	padding-left: var(--spacing-4x);
	margin-top: var(--spacing-4x);
}

.editor {
	margin-top: var(--spacing-4x);
}

.editable {
	&:hover {
		cursor: pointer;
		background-color: var(--color-background-panel);
	}
}