:root {
	--max-width: calc(100vw - 17px);
}

/* Provide sufficient contrast against white background */
a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

@font-face {
	font-family: 'MuliLight';
	src: local('Muli Light'), url('./fonts/Muli/static/Muli-Light.ttf');
}

@font-face {
	font-family: 'MuliSemiBold';
	src: local('Muli Semi Bold'), url('./fonts/Muli/static/Muli-SemiBold.ttf');
}

@font-face {
	font-family: 'MuliBold';
	src: local('Muli Bold'), url('./fonts/Muli/static/Muli-Bold.ttf');
}

@font-face {
	font-family: 'MuliExtraBold';
	src: local('Muli Extra Bold'), url('./fonts/Muli/static/Muli-ExtraBold.ttf');
}

@font-face {
	font-family: 'MuliRegular';
	src: local('Muli Regular'), url('./fonts/Muli/static/Muli-Regular.ttf');
}

body {
	font-family: MuliLight;
}

h1 {
	font-family: MuliBold;
}

h1,
h2,
h3 {
	word-wrap: break-word;
}

#root {
	max-width: 100vw;
	// overflow-y: auto; - Destroys menu css sticky
}
