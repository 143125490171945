.urlPanel {
	background: var(--color-background-panel);
	padding: var(--spacing-8x) var(--spacing-4x);
}

.contentContainer {
	padding: var(--spacing-8x) var(--spacing-4x);
}

.descriptionField {
    textarea {
      border: 0;
			border-radius: 0;
			outline: 0;
			padding-top: var(--spacing-2x);
		&:focus {
			background-color: var(--color-background-panel);
		}
	}
}

.titleField {
	font-family: MuliBold;
	input {
		outline: 0;
		border: 0;
		border-radius: 0;
		&:focus {
			background-color: var(--color-background-panel);
		}
	}
}

.deleteButton {
	display: block;
	margin: var(--spacing-8x) auto 0;
}

.url {
	input {
		border-radius: 0;
	}
}
