a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}

.navbar {
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
	align-items: baseline;
}
