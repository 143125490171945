.thumbnail {
	margin-bottom: var(--spacing-6x);
	color: var(--color-text-default);
	text-decoration: none;
	&:hover {
		color: var(--color-text-default);
		text-decoration: none;
	}
}

.thumbnailWithImage {
	display: grid;
	grid-template-columns: 150px auto;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		.media {
			height: 200px;
		}
	}
}

.media {
	background-position: center;
	background-size: cover;
}

.thumbnailContent {
	background-color: var(--color-background-panel);
	padding: var(--spacing-4x);
	height: 100%;
}
