.buttonGroup {
	button {
		width: auto;
	}
}

.buttonGroup > * {
	margin-right: var(--spacing-2x);
	&:last-child {
		margin-right: 0;
	}
}

.vertical {
	display: flex;
	align-items: center;
	flex-direction: column;
}
