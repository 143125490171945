.container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

	.imgWrapper {
		text-align: center;
		display: flex;
		justify-content: center;

		.ipadArt {
			height: 360px;
			min-width: 280px;
		}
	}
}
