@import 'scss-variables/breakpoints.scss';

.navigationMenu {
	width: 100%;
	padding-right: var(--spacing-8x);

	@media (max-width: $--max-size-mobile) {
		margin-bottom: var(--spacing-8x);
	}
}

.navigationMenuItem {
	outline: 0;
	&:focus {
		outline: 0;
	}
	display: block;
	width: 100%;
	text-align: left;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--color-border-light);
	padding-top: var(--spacing-4x);
	&:not(.selected) {
		&:hover {
			background-color: var(--color-background-panel);
		}
	}
}

.selected {
	color: #ff5a5d;
}
