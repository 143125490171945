.title {
	min-height: 48px;
}

.titleEditWrapper {
	display: grid;
	grid-template-columns: auto 120px;
	button {
		width: auto;
		border: none;
		background-color: var(--color-background-panel);
		border: none;
		border-radius: 0;
	}
}

.editable{
	cursor: pointer;
	&:hover {
		background-color: var(--color-background-panel);
	}
}

.editing {
	background-color: var(--color-background-panel);
}