.thumbnailList {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: var(--spacing-4x);
	padding: var(--spacing-8x) var(--spacing-4x) var(--spacing-4x) var(--spacing-4x);
}

.noResults {
	padding: var(--spacing-4x);
	color: var(--color-text-meta);
	text-align: center;
}