.navLink {
	display: block;
	padding: 0.5rem 1rem;
	color: var(--color-text-default);
	&:hover {
		text-decoration: none;
		color: var(--color-text-default);
	}
}

.disabled {
	color: var(--color-text-meta);
	cursor: not-allowed;
	&:hover {
		color: var(--color-text-meta);
	}
}

.userMenuItem {
	cursor: pointer;
	position: relative;
	z-index: 2;

	svg {
		margin-left: var(--spacing-2x);
	}

	.logoutButton {
		position: absolute;
		top: 30px;
		right: 0;
		background-color: white;
	}
}

.username {
	margin-right: var(--spacing-2x);
}
