.imageEdit {
	padding: 16px;
}

.addImage {
	height: 423px;
	background: var(--color-background-panel);
	text-align: center;
	padding-top: 168px;
	position: relative;
}

.addImageIcon {
	height: 28px;
	width: 28px;
}

.addImageText {
	margin-top: 8px;
	font-family: MuliBold;
	color: var(--color-text-meta);
	font-size: 16px;
}

.addImageUrl {
	border-radius: 0;
	margin-top: 16px;
}

.image {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.deleteButton {
	display: block;
	margin: var(--spacing-8x) auto var(--spacing-4x);
}

.descriptionField {
	textarea {
		border: 0;
		border-radius: 0;
		outline: 0;
		padding-top: var(--spacing-2x);
		&:hover {
			background-color: var(--color-background-panel);
		}
		&:focus {
			background-color: var(--color-background-panel);
		}
	}
}

.descriptionFieldWrapper {
	padding: var(--spacing-4x);
}
